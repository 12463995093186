<template>
  <div
    ref="dragDrop"
    class="md:tw-flex md:tw-justify-start tw-flex-wrap tw-w-full md:tw-items-center tw-mb-1"
    :class="[dropActive ? 'image-wrapper--active' : null, identifier]"
    @drop.prevent="saveFile('drop', $event)"
    @dragenter="dropActive = true"
    @dragleave="dropActive = false"
    @dragover.prevent
  >
    <div
      class="tw-w-full tw-px-4 tw-py-6 image-wrapper tw-mx-auto tw-flex tw-justify-center tw-flex-wrap tw-items-center tw-mx-auto md:tw-mx-0"
      :class="[hasMaxWidth ? 'tw-max-w-[640px]' : 'tw-max-w-full']"
      v-if="image && !isPicture"
    >
      <div class="tw-flex tw-justify-between tw-px-4 tw-w-full">
        <div class="tw-flex tw-w-full tw-items-center">
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1658395421/getequityV2/doc-pin_sms6y6.svg"
            alt="logo"
          />
          <div class="tw-ml-4">
            <a :href="image" target="_blank" rel="noopener noreferrer">
              <h3 class="tw-text-sm tw-font-bold tw-break-all">
                {{ file?.name || documentName || "Document" }}
              </h3>
            </a>
            <span class="tw-text-gray tw-mr-1" v-if="file">
              {{
                (file?.size / 1000000).toFixed(2) === "0.00"
                  ? "< 1"
                  : (file?.size / 1000000).toFixed(2)
              }}
              MB
              <!-- {{ Math.round(file?.size / 1024) > 1024 ? "MB" : "KB" }} -->
            </span>
          </div>
          <button
            type="button"
            @click="clearAttachment"
            class="tw-w-6 tw-h-6 tw-rounded-full tw-border tw-flex tw-items-center tw-justify-center tw-border-[#DB4343] tw-ml-auto hover:tw-bg-white"
          >
            <img
              src="https://res.cloudinary.com/djalafcj9/image/upload/v1658398422/getequityV2/Vector_dyef6b.svg"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-w-full tw-px-4 tw-py-6 image-wrapper tw-mx-auto tw-flex tw-justify-center tw-flex-wrap tw-items-center tw-mx-auto md:tw-mx-0"
      :class="[
        hasMaxWidth ? 'tw-max-w-[640px]' : 'tw-max-w-full',
        validated && !image && required ? 'image-wrapper--error' : '',
      ]"
      @dragenter="dropActive = true"
    >
      <label
        v-if="
          currentValue &&
            currentValue.includes('.pdf') &&
            accept.includes('pdf')
        "
        for="pdf"
      >
        <p @click="previewBase64" class="tw-h-8 tw-underline tw-cursor-pointer">
          Preview File
        </p>
      </label>
      <p class="tw-text-xs" v-else-if="image && pdfName">{{ pdfName }}</p>
      <slot> </slot>
      <div class="tw-w-full tw-mt-2">
        <label class="tw-text-sm tw-font-semibold tw-cursor-pointer tw-block">
          <div
            @dragenter="dropActive = true"
            class="tw-bg-[#F0F4FD] tw-rounded-full tw-p-2 tw-h-[80px] tw-w-[80px] tw-flex tw-items-center tw-justify-center tw-mx-auto tw-mb-2"
            v-if="
              image?.length === 0 ||
                (image && isPicture) ||
                (currentValue && !currentValue.includes('pdf'))
            "
          >
            <img
              class="tw-h-[64px] tw-w-[64px] tw-rounded-full"
              :src="imageSource"
            />
          </div>
          <span
            class="tw-bg-primary button tw-mt-4 tw-flex tw-text-center tw-mx-auto"
          >
            {{
              image?.length === 0 || currentValue?.length === 0
                ? "Upload"
                : "Change"
            }}
          </span>
          <input
            type="file"
            name=""
            class="tw-text-tertiary tw-hidden tw-w-20"
            title="upload"
            id="file"
            ref="tokenImage"
            :accept="accept"
            @change="previewImage"
          />
        </label>
      </div>
      <p class="tw-text-black-light tw-text-xs tw-mt-2">Maximum size: 5MB</p>
    </div>
    <p
      class="tw-text-red-error tw-text-center tw-w-full tw-mt-3"
      v-if="fileMessage"
    >
      {{ fileMessage }}
    </p>
    <p
      class="tw-text-red-error tw-text-left tw-w-full tw-mt-3"
      v-if="validated && !image && required"
    >
      Please upload a file
    </p>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapActions } from "vuex";
  import responseHandler from "@/utils/responseHandler.js";
  export default {
    name: "DragNDrop",
    data() {
      return {
        isPicture: false,
        pdfName: "",
        dropActive: false,
        fileMessage: "",
        image: "",
        validated: false,
        file: "",
      };
    },
    emits: [
      "file-added",
      "upload-complete",
      "re-upload",
      "upload-error",
      "is-valid",
      "remove-file",
    ],
    props: {
      accept: {
        type: String,
        required: true,
      },
      startUpload: {
        type: Boolean,
        required: true,
      },
      validate: {
        type: Boolean,
        required: false,
      },
      identifier: {
        // class name that is unique to any form the component is being used --- useful for getting the total number of uploads on a form
        type: String,
        required: false,
        default: () => "dragDrop",
      },
      hasMaxWidth: {
        type: Boolean,
        default: () => true,
        required: false,
      },
      currentValue: {
        type: String,
        required: false,
      },
      documentName: {
        type: String,
        required: false,
      },
      required: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      processFile: {
        // use this prop when you want to read the content of the file (e.g csv).
        type: Boolean,
        required: false,
        default: () => false,
      },
    },
    mounted() {
      this.image = this.currentValue;
    },
    watch: {
      image(val) {
        if (
          val &&
          (val.includes(".png") ||
            val.includes(".jpg") ||
            val.includes(".jpeg"))
        ) {
          this.isPicture = true;
        }
        this.$emit("file-added", val);
      },
      currentValue(val) {
        if (val !== this.image) {
          this.image = val;
        }
      },
      startUpload: "uploadImage",
      validate(val) {
        if (val) {
          this.validated = true;
          if (this.required) {
            if (this.image) {
              this.$emit("is-valid", true);
            } else {
              this.$emit("is-valid", false);
            }
          } else {
            this.$emit("is-valid", true);
          }
        }
      },
    },
    computed: {
      imageSource() {
        let imageSource = null;
        if (this.image || this.currentValue) {
          imageSource = this.image || this.currentValue;
        } else {
          imageSource =
            "https://res.cloudinary.com/djalafcj9/image/upload/v1654002027/getequityV2/Group_42927_tdvyo1.png";
        }
        return imageSource;
      },
    },
    methods: {
      ...mapActions("fileUploads", ["setStatus", "setLoading"]),
      saveFile(type, e) {
        let file = null;
        if (type === "drop") {
          this.dropActive = false;
          this.error = false;
          file = e.dataTransfer.files;
          let supportedFiles = null;
          //check if current component accepts '.pdf'
          if (this.accept.includes(".pdf")) {
            let accepts = this.accept.split(",");
            //get index of '.pdf' from the list
            const indexOfPdf = accepts.indexOf(".pdf");
            // replace with 'application/pdf'
            accepts.splice(indexOfPdf);
            supportedFiles = [...accepts, "application/pdf"];
          }
          if (file && supportedFiles.includes(file[0].type)) {
            this.fileMessage = "";
            this.previewImage(file, "dragNdrop");
          } else if (!supportedFiles.includes(file[0].type)) {
            this.fileMessage = "Invalid file type";
            return;
          } else {
            return (this.error = true);
          }
        } else {
          file = e.target.files;
          this.file = file;
          this.$emit("file-upload", file);
        }
      },
      previewBase64() {
        const pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='" +
            encodeURI(this.currentValue) +
            "'></iframe>"
        );
      },
      previewImage(event, type) {
        // Reference to the DOM input element
        const input = event.target || event;
        const file = type === "dragNdrop" ? event : input.files;
        if (file.length > 0) {
          const sizeRaw = file[0].size;
          const fileSize = Math.round(sizeRaw / 1024);
          const fileType = file[0].type;
          this.file = file[0];
          if (fileType.includes("image")) {
            this.isPicture = true;
          } else {
            this.pdfName = file[0].name;
            this.isPicture = false;
          }
          // Ensure that you have a file before attempting to read it
          if (file && file[0]) {
            if (fileSize > 5120) {
              this.fileMessage =
                "File size too large, Please select another file.";
              this.image = "";
            } else {
              this.fileMessage = "";
              var fileReader = new FileReader();
              fileReader.onload = (e) => {
                this.image = e.target.result;
              };
              if (this.processFile) {
                return fileReader.readAsText(file[0]);
              }
              return fileReader.readAsDataURL(file[0]);
            }
          }
        }
      },
      uploadImage(val) {
        if (this.image) {
          if (val) {
            if (this.image.includes("cloudinary")) {
              this.$emit("upload-complete", this.image);
            } else {
              this.setStatus(true);
              const formData = new FormData();
              formData.append("file", this.image);
              formData.append(
                "upload_preset",
                process.env.VUE_APP_CLOUDINARY_UPLOAD_SECOND_PRESET
              );
              this.setLoading(true);
              var instance = axios.create();
              // eslint-disable-next-line dot-notation
              delete instance.defaults.headers.common["Authorization"];
              instance
                .post(
                  "https://api.Cloudinary.com/v1_1/djalafcj9/image/upload",
                  formData
                )
                .then((response) => {
                  this.setStatus(false);
                  this.image = response.data.secure_url;
                  this.$emit("upload-complete", response.data.secure_url);
                  this.$emit("re-upload", true);
                })
                .catch(() => {
                  this.setStatus(false);
                  this.$emit("re-upload", true);
                  this.$emit("upload-error");
                  responseHandler.handleError({
                    message:
                      "Something went wrong, please upload another file or contact support",
                  });
                });
            }
          } else if (!this.image.includes("cloudinary")) {
            this.isPicture = false;
            this.pdfName = "";
            this.fileMessage = "";
            this.image = "";
            this.$emit("re-upload", true);
          }
        } else if (!this.required) {
          this.$emit("upload-complete", this.image);
        }
      },
      openFilePicker() {
        const tokenImagePicker = this.$refs.tokenImage;
        tokenImagePicker.click();
      },
      clearAttachment() {
        this.image = "";
        this.$emit("remove-file");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .picture {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
  }

  @keyframes vibrate {
    from {
      transform: translateX(-5px);
    }
    to {
      transform: translateX(5px);
    }
  }

  .image-wrapper {
    border: 1px dashed #c2d3ff;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    background: #fafbff;
    &--active {
      background-color: rgba(150, 150, 150, 0.1);
      animation: vibrate 100ms ease-in-out -1s infinite alternate-reverse none;
      border: 1px solid #c2d3ff;
    }
    &--error {
      border: 1px solid #db4343;
    }
  }

  .text {
    &--grey {
      color: #a3a8b3;
    }
  }

  .button {
    background: #4d84ff;
    border-radius: 4px;
    color: #fff;
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .label-light {
    color: #707070;
  }
  .label-dark {
    color: #f1f2f8;
  }

  .upload-spv__dragdrop .image-wrapper {
    max-width: 100%;
  }
</style>
