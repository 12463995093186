<template>
  <InputFieldLabel
    :label="label"
    :inputActive="inputActive"
    @label-click="focusInput"
    :required="required"
  >
    <input
      :type="name === 'email' ? 'email' : type ? type : 'text'"
      :name="label"
      :ref="name"
      :id="name"
      :minlength="minLength"
      :maxlength="maxLength"
      :required="required"
      :readonly="disabled"
      class="tw-w-full tw-rounded custom-form__input tw-pl-6 tw-text-secondary"
      :class="[
        height,
        border ? border : 'custom-form__input--default',
        v$.value.$error ? 'custom-form__input--error' : null,
        disabled ? 'tw-cursor-not-allowed tw-bg-gray-light' : null,
      ]"
      v-model="value"
      @focus="inputActive = true"
      @input="$emit('input')"
      @keypress="validateAsNumber ? isNumber($event) : null"
      @blur="handleBlur"
    />
    <p class="tw-text-red-error tw-font-normal" v-if="v$.value.$error">
      {{ v$.$errors[0].$message }}
    </p>
  </InputFieldLabel>
</template>

<script>
  import InputFieldLabel from "./InputFieldLabel";
  import { required, helpers, email } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  import { isNumber, formatAmountToDollar } from "@/utils/helpers.js";
  export default {
    name: "InputField",
    data() {
      return {
        inputActive: false,
        value: null,
        v$: useValidate(),
        isValid: true,
      };
    },
    components: {
      InputFieldLabel,
    },
    validations() {
      return {
        value: {
          required: this.required ? required : false,
          numericIfNumber: helpers.withMessage(
            "This field only accepts numbers",
            this.numericIfNumber
          ),
          email: this.type === "email" ? email : false,
          validateEmail: helpers.withMessage(
            () => "This field only accepts valid email",
            this.validateEmail
          ),
          validatePhoneNumber: helpers.withMessage(
            "This field only accepts valid phone number",
            this.validatePhoneNumber
          ),
          validateMaxValue: helpers.withMessage(
            () =>
              `${this.maxValueErrorMessage ||
                "This is more than the maximum value of"} ${
                this.valueType === "amount"
                  ? this.formatAmountToDollar(this.maxValue, 2, "USD")
                  : this.maxValue
              }`,
            this.validateMaxValue
          ),
          validateZeroValue: helpers.withMessage(
            () => `${this.zeroValueErrorMessage || "zero is not allowed"}`,
            this.validateZeroValue
          ),
          validateSameAs: helpers.withMessage(
            () => `This value must match ${this.sameAs}`,
            this.validateSameAs
          ),
          validateMinValue: helpers.withMessage(
            () =>
              `This field requires a minimum value of ${
                this.valueType === "amount"
                  ? this.formatAmountToDollar(this.minValue, 2, "USD")
                  : this.minValue
              }`,
            this.validateMinValue
          ),
          validateUrl: helpers.withMessage(
            `Please enter a valid link`,
            this.validateUrl
          ),
        },
      };
    },
    props: {
      label: {
        type: String,
        required: true,
        default: () => "",
      },
      // value that the input value should not be greater than
      maxValue: {
        type: [Number, String],
        required: false,
      },
      minValue: {
        type: [Number, String],
        required: false,
      },
      minLength: {
        type: Number,
        required: false,
      },
      maxLength: {
        type: Number,
        required: false,
      },
      valueType: {
        type: String,
        required: false,
        default: () => "amount",
      },
      zeroValue: {
        type: Boolean,
        required: false,
      },
      zeroValueErrorMessage: {
        type: String,
        required: false,
      },
      maxValueErrorMessage: {
        type: String,
        required: false,
      },
      validate: {
        type: Boolean,
        required: true,
      },
      sameAs: { type: String, default: () => "" },
      type: {
        type: String,
        required: false,
      },
      //border color class for the input
      border: {
        type: String,
        required: false,
      },
      //'type' can be text but still only accept numbers(one reason is because in order to format input, you need to make the text a string otherwise the input field would flag "," in "7,000,000"), hence this prop;
      validateAsNumber: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      name: {
        type: String,
        required: true,
        default: () => "",
      },
      currentValue: {
        required: false,
        default: () => "",
      },
      required: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      height: {
        type: String,
        required: false,
        default: () => "tw-h-12",
      },
      disabled: {
        type: Boolean,
        required: false,
      },
    },
    emits: ["input-changed", "input", "is-valid", "is-blurred"],
    watch: {
      value(val) {
        if ((val && val.length > 0) || (val && val > 0)) {
          this.inputActive = true;
          this.isValid = true;
        }
        if (!val || val.length === 0) {
          this.isValid = false;
        }
        this.$emit("input-changed", { name: this.name, value: val });
      },
      currentValue(val) {
        if (val !== this.value) {
          this.value = val;
        }
      },
      validate(val) {
        if (val) {
          setTimeout(() => {
            this.validateInput();
          }, 500);
        }
      },
    },
    methods: {
      isNumber,
      formatAmountToDollar,
      focusInput() {
        this.$refs[this.name].focus();
      },
      handleBlur() {
        !this.value || this.value.length === 0
          ? (this.inputActive = false)
          : null;
        this.$emit("is-blurred");
      },
      validateInput() {
        this.v$.value.$touch();
        //check if field is required
        if (this.required) {
          this.isValid = !this.v$.value.$error ? true : false;
        } else {
          this.isValid = true;
        }
        this.$emit("is-valid", this.isValid);
      },
      numericIfNumber(value) {
        if (this.type === "number") {
          //check if value passes as a number and return true or false
          return !isNaN(value);
        } else if (this.validateAsNumber) {
          //first remove commas from number
          const number = String(value)
            .split(",")
            .join("");
          return !isNaN(Number(number));
        }
        return true;
      },
      validateEmail(email) {
        if (this.type === "email") {
          return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        }
        return true;
      },
      validatePhoneNumber(number) {
        if (this.type === "tel") {
          return number.match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im
          );
          // Valid formats:
          //   (123) 456-7890
          //   (123)456-7890
          //   123-456-7890
          //   123.456.7890
          //   1234567890
          //   +31636363634
          //   075-63546725
        }
        return true;
      },
      validateUrl(val) {
        if (this.type === "url" && this.required) {
          const regExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
          return regExp.test(val);
        }
        return true;
      },
      validateMaxValue(value) {
        if (this.maxValue) {
          const number = isNaN(value) ? value.split(",").join("") : value;
          const maxValue = String(this.maxValue)
            .split(",")
            .join("");
          const isLessThanMaxValue = Number(number) <= maxValue;
          return isLessThanMaxValue;
        }
        return true;
      },
      validateMinValue(value) {
        if (this.minValue) {
          const number = String(value)
            .split(",")
            .join("");
          const minValue = String(this.minValue)
            .split(",")
            .join("");
          const isGreaterThanMinValue = Number(number) >= minValue;
          return isGreaterThanMinValue;
        }
        return true;
      },
      validateZeroValue(value) {
        if (this.zeroValue) {
          const number = value.split(",").join("");
          // const maxValue = String(this.maxValue).split(",").join("");
          const isLessThanZeroValue = Math.ceil(Number(number)) > 0;
          return isLessThanZeroValue;
        }
        return true;
      },
      validateSameAs(val) {
        if (this.sameAs) {
          if (val.toLowerCase() === this.sameAs.toLowerCase()) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
    },
    mounted() {
      this.isValid = this.value ? true : false;
      this.value = this.currentValue;
    },
  };
</script>

<style lang="scss" scoped>
  .custom-form {
    &__input {
      transition: all 500ms ease-in-out;
      padding-left: 28px;
      background-color: #fff;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &--default {
        border: 1px solid #e1e6f0;
      }
      &:read-only {
        background-color: #f0f4fd;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
      &--error {
        border: 1px solid #db4343;
      }
      &:focus {
        border: 1px solid #4d84ff;
      }
    }
  }
</style>
